






































































































































import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, Ref, ref } from '@vue/composition-api'
import auth from '@/composables/auth'
import GLoader from '@/components/g/Loader.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'

export default defineComponent({
  name: 'MobilitySimulations',
  components: {
    CustomLink,
    GLoader,
  },

  setup(_props, ctx) {
    const { user } = useState(['user'])
    const { i18n } = useState('my', ['i18n'])
    const isLoad: Ref<boolean> = ref(false)
    const simulations: Ref<any[]> = ref([])
    const eanSimulations: Ref<Array<string>> = ref([])
    const errorLoadService: Ref<boolean> = ref(false)
    const { currentLang } = useGetters(['currentLang'])
    const tokenParam: Ref<string> = ref('')

    onBeforeMount(async () => {
      if (auth.isLogged) {
        let storedToken: string | null = window.localStorage.getItem('token')
        if (storedToken) {
          storedToken = storedToken.substring('Bearer '.length)
          tokenParam.value = `?ssoToken=${storedToken}`
        }
      } else {
        await ctx.root.$router.push(`/${currentLang.value}/signin/`)
      }

      await axios
        .get(`${getApiUrl()}/guidance/me/vehicules`)
        .then(res => {
          simulations.value = res.data
          simulations.value.forEach(s => {
            if (eanSimulations.value.indexOf(s.ean) < 0) {
              eanSimulations.value.push(s.ean)
            }
          })
          isLoad.value = true
        })
        .catch(e => {
          isLoad.value = true
          errorLoadService.value = true
          logger.trace('[simulations:loadDatas] FAILED', e)
        })
    })

    return {
      i18n,
      user,
      isLogged: auth.isLogged,
      isLoad,
      simulations,
      errorLoadService,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      ...useGetters('user', ['localizedEans']),
      eanSimulations,
      tokenParam,
    }
  },
})
